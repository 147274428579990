import { Wistia } from 'wistia_namespace.js';
import { h, render } from 'preact';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { defineControl } from 'embeds/shared/control_definitions.js';
import { destroyControl } from '../../../../../../shared/control-lifecycle.js';
import WistiaLogoSVG from './WistiaLogoSVG.jsx';
import WistiaLogoSmallSVG from './WistiaLogoSmallSVG.jsx';
import Control from '../../../shared/controls/Control.js';

class WistiaLogoControl extends Control {
  constructor(video) {
    super(video);
    this.video = video;
    this.largestButtonWidth = WistiaLogoControl.largestButtonWidth;

    this.unbinds.push(video.on('widthchange', this.renderButton));
  }

  mountButton(buttonElem) {
    this.buttonElem = buttonElem;
    this.setButtonLabel('Wistia Logo -- Learn More');
    this.renderButton();
  }

  destroy() {
    destroyControl(this);
  }

  onControlPropsUpdated() {
    if (this._destroyed) {
      return;
    }
    this.renderButton();
  }

  // Controls are always buttons, but because this control redirects us to a new page,
  // for accessibility purposes, we need it to actually be a link.
  // This is a gross workaround to tell `ControlBarButton` to render a link instead of a button.
  isActuallyLink() {
    return true;
  }

  linkAttrs() {
    return {
      href: `https://wistia.com/about-wistia?utm_type=player&utm_campaign=wistia-branding&utm_medium=referral&utm_source=wistia&about=${
        this.video._mediaData.accountKey.match(/\d+/)[0]
      }`,
      ariaLabel: "Learn more about Wistia's products",
      target: '_blank',
    };
  }

  onClickButton() {
    window.open(this.linkAttrs().href, this.linkAttrs().target);
  }

  isEllipsisVisible() {
    const buttonsOnRight = this.video._impl.ui?.getControlsByType('control-bar-right') || [];
    const playbar = this.video._impl.ui?.getControlsByType('playbar') || [];

    const willMountEllipsis =
      buttonsOnRight.length > 0 &&
      playbar.length > 0 &&
      this.video._impl.ui &&
      !this.video._impl.ui.shouldShowMoreDefaultValue();

    return this.video._impl.ui?.getControlsByType('ellipsis').length > 0 || willMountEllipsis;
  }

  renderButton = () => {
    if (!this.buttonElem) {
      return;
    }

    render(this.isEllipsisVisible() ? <WistiaLogoSmallSVG /> : <WistiaLogoSVG />, this.buttonElem);
    this.reactMounts.button = [this.buttonElem];
  };

  wistiaLogoButtonWidth() {
    return this.isEllipsisVisible() ? 40 : this.largestButtonWidth;
  }
}

WistiaLogoControl.handle = 'wistiaLogo';
WistiaLogoControl.type = 'wistia-logo';
WistiaLogoControl.sortValue = 2000;
WistiaLogoControl.largestButtonWidth = 120;

WistiaLogoControl.shouldMount = (video) => {
  return video.plugin.wistiaLogo;
};

defineControl(WistiaLogoControl);
